<template>
    <v-select
        v-model="valueLocal"
        :items="enumValues"
        :label="field.titleSingular"
        :rules="field.rules"
        :required="field.isRequired"
        :multiple="field.isMultiple"
        :dense="isDense"
        :readonly="isReadonly"
        :disabled="isDisabled" />
</template>

<script>
import enumService from "@/services/enumService";
import enumComponents from "@/enums/enumComponents";

export default {
    props: {
        value: {
            type: String,
            default: null,
        },
        field: {
            type: Object,
            default: null,
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
        filter: {
            type: Object,
            default: null,
        },
        isDense: {
            type: Boolean,
            default: false,
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        isReadonly: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            enumComponents,
        };
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        enumValues() {
            return enumService.enumAsSelectList(
                this.enumComponents.filter(e => e.name === this.field.type)[0].component
            );
        },
    },
};
</script>
